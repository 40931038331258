<template>
	<div id="personal-info">
		<hr class="thicker-radius" />
		<legend>Personal Info</legend>
		<pre>{{ JSON.stringify(personalInfo, null, "\t") }}</pre>
		<!-- <label for=""></label>
			<input id="" type="text" :placeholder="" v-model="" /> -->
		<label for="name-first">nameFirst</label>
		<input id="name-first" type="text" :placeholder="nameFirst" v-model="nameFirst" />
		<label for="name-last">nameLast</label>
		<input id="name-last" type="text" :placeholder="nameLast" v-model="nameLast" />
		<label for="birth-date">birthDate</label>
		<input id="birth-date" type="date" :placeholder="birthDate" v-model="birthDate" />
		<label for="nationality">nationality</label>
		<input id="nationality" type="text" :placeholder="nationality" v-model="nationality" />
		<label for="address-county">addressCounty</label>
		<input id="address-county" type="text" :placeholder="addressCounty" v-model="addressCounty" />
		<label for="address-ZipCode">addressZipCode</label>
		<input id="address-ZipCode" type="text" :placeholder="addressZipCode" v-model="addressZipCode" />
		<label for="address-email">emailAddress</label>
		<input id="address-email" type="text" :placeholder="emailAddress" v-model="emailAddress" />
	</div>
</template>

<script>
export default {
	name: "PersonalInfoBasicTransactions",
	props: {
		playerState: Object,
		userProfile: Object,
		languageStrings: Object,
		languageErrorString: Object,
	},
	data() {
		return {
			personalInfo: this?.userProfile?.personalInfo,
			nameFirst: "",
			nameLast: "",
			nameLast2: "",
			birthDate: new Date().toISOString().split("T")[0],
			nationality: "",
			emailAddress: "",
			emailVerificationStatus: "",
			addressCountry: "",
			addressZipCode: "",
		};
	},
	watch: {},
	created() {
		this.populateFields();
	},
	mounted() {},
	methods: {
		populateFields() {
			this.nameFirst = this?.personalInfo?.nameFirst;
			this.nameLast = this?.personalInfo?.nameLast;
			this.nameLast2 = this?.personalInfo?.nameLast2;
			this.birthDate = this?.personalInfo?.birthDate;
			this.nationality = this?.personalInfo?.nationality;
			this.emailAddress = this?.personalInfo?.emailAddress;
			this.emailVerificationStatus = this?.personalInfo?.emailVerificationStatus;
			this.addressCountry = this?.personalInfo?.addressCountry;
			this.addressZipCode = this?.personalInfo?.addressZipCode;
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#personal-info {
	display: flex;
	flex-flow: column;
}
</style>
